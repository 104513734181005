<template>
  <div class="pageContol studyRecord">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="toOrganizationList" class="cur-a">机构列表</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a">学员列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学习记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="seachStyle">
          <el-row :gutter="20" type="flex" justify="end">
            <!-- <el-col :sm="12" :md="8" :lg="6" class="searchList">
              <span class="listLabel">课件名称:</span>
              <el-input
                class="listInput"
                v-model="searchData.courseName"
                placeholder="请输入课件名称"
                size="small"
                clearable
              />
            </el-col>-->

            <el-col :sm="12" :md="8" :lg="6" class="searchList" style="justify-content: flex-end;">
              <div class="listBtn">
                <!-- <el-button type="primary" class="listBtnS" round @click="getData()">查询</el-button> -->
                <el-button type="primary" class="listBtnS" round @click="doExport(1)">导出</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                width="250px"
              />

              <el-table-column label="课件名称" align="left" prop="catalogNames" minWidth="200">
                <template slot-scope="scope">
                  <span>
                    {{
                    getName(scope.row.catalogNames)
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="视频时长" align="left" prop="kpointDurations" minWidth="150"></el-table-column>
              <el-table-column
                label="开始学习时间"
                align="left"
                show-overflow-tooltip
                prop="startTime"
                width="150"
              >
                <template slot-scope="scope">
                  <span>
                    {{
                    scope.row.startTime|moment
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="结束学习时间"
                align="left"
                show-overflow-tooltip
                prop="endTime"
                width="150"
              >
                <template slot-scope="scope">
                  <span>
                    {{
                    scope.row.endTime|moment
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="学习时长"
                align="right"
                show-overflow-tooltip
                prop="studyTime"
                width="100"
              />
              <el-table-column
                label="监管认证次数"
                align="right"
                show-overflow-tooltip
                prop="governmentNum"
                width="100"
              />
              <el-table-column
                label="机构认证次数"
                align="right"
                show-overflow-tooltip
                prop="agencyNum"
                width="100"
              />
              <el-table-column
                label="随机认证次数"
                align="rights"
                show-overflow-tooltip
                prop="randomNum"
                width="100"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";

export default {
  name: "sjzRegulator/studyRecord",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      searchData: {
        courseName: ""
      }
    };
  },
  created() {},
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    getName(name) {
      return name.replace(/,/g, "，");
    },
    //机构列表
    toOrganizationList() {
      this.$router.push({
        path: "/web/SupervisionEnd/sjzRegulator/organizationList"
      });
    },
    // 导出
    doExport(pageNum = 1) {
      const params = {
        pageSize: this.pageSize,
        projectId: this.$route.query.projectId,
        userId: this.$route.query.userId,
        //  projectId:"100308",
        // userId:"77914",
        pageNum
      };
      if (this.searchData.courseName) {
        params.courseName = this.searchData.courseName;
      }
      this.$post("/gov/course/study/track/exportStudyRecordList", params).then(
        res => {
          if (res.status == "0") {
            window.open(res.data);
          }
        }
      );
    },
    getData(pageNum = 1) {
      const params = {
        pageSize: this.pageSize,
        projectId: this.$route.query.projectId,
        userId: this.$route.query.userId
        //  projectId:"100308",
        // userId:"77914",
      };
      if (this.searchData.courseName) {
        params.courseName = this.searchData.courseName;
      }
      this.doFetch({
        url: "/gov/course/study/track/queryStudyRecordList",
        params,
        pageNum
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
<style lang="less">
</style>
